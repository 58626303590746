import { Button, Form, Modal, Upload } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryRequest } from "../../../services/apollo/api_service";
import { fileQuery } from "../../../helpers/constants";
import { UploadOutlined } from "@ant-design/icons";
import { getUUID } from "../../../helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  mutation_upload_excel,
  query_get_asynchronous_request,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

let modalInstance = Modal;

const MandramRenewalFileUpload = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [file_list, set_file_list] = useState([]);
  console.log("file_list", file_list);
  const [error_message, set_error_message] = useState("");
  const [url, set_url] = useState("");
  const [mandram_excel_upload_status, set_mandram_excel_upload_status] =
    useState("");
  const [mandram_excel_upload_error, set_mandram_excel_upload_error] =
    useState("");


  const {
    id: excel_upload_id,
    status: excel_upload_status,
    error: excel_upload_error,
  } = useDynamicSelector("excel_mandram_renewal");

  const {
    loading: mandram_activation_loading,
    status: asynchronous_status,
    id:mandram_activation_request_id,
    response,
  } = useDynamicSelector("get_asynchronous_request");
  const [mandramExcelUploadDetails, setMandramExcelUploadDetails] = useState([]);
  console.log("mandramExcelUploadDetails",mandramExcelUploadDetails);
  


  useEffect(() => {
    if (response) {
      const mandram_excel_upload_details = JSON.parse(response);
      set_mandram_excel_upload_status(mandram_excel_upload_details?.status);
      set_mandram_excel_upload_error(
        mandram_excel_upload_details?.message
      );
    }
  }, [response]);

  useEffect(() => {
    if (url) {
      mandram_renewal_excel_upload();
    }
  }, [url]);

  useEffect(() => {
    if (excel_upload_status === "success") {
      modalInstance.info({
        title: "Activation Process",
        content: (
          <div>
            <p>Mandrams Activation is processing...</p>
            <p>Do not close or refresh the page.</p>
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: { display: "none" },
        },
        getContainer: document.body,
        className: "custom-modal",
        keyboard: false,
      });
      get_asynchronous_request();
      const interval_id = setInterval(() => get_asynchronous_request(), 5000);
      return () => clearInterval(interval_id);
    }
  }, [excel_upload_status]);

  useEffect(() => {
    if (asynchronous_status === "completed"&&mandram_activation_request_id) {
      set_url("");
      closeModalInstance();
      toast.success("Mandrams are Activated Successfully")
      dispatch(dynamicClear("excel_mandram_renewal"));
      dispatch(dynamicClear("get_asynchronous_request"));
      set_mandram_excel_upload_status("")
    } else if (asynchronous_status === "failed") {
      closeModalInstance();
      set_url("");
      toast.error("Something went wrong");
      dispatch(dynamicClear("excel_mandram_renewal"));
      dispatch(dynamicClear("get_asynchronous_request"));
      set_mandram_excel_upload_status("");
      set_mandram_excel_upload_error("");
    }
  }, [
    mandram_activation_request_id,
    mandram_excel_upload_error,
    asynchronous_status,
  ]);

  const closeModalInstance = () => {
    if (modalInstance) {
      modalInstance.destroyAll();
    }
  };

  const mandram_renewal_excel_upload = () => {
    let key = [{ key: "excel_mandram_renewal", loading: true }];
    let query = mutation_upload_excel;
    let variables = {
      excel_url: url,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_asynchronous_request = () => {
    let key = [{ key: "get_asynchronous_request", loading: true }];
    let query = query_get_asynchronous_request;
    let variables = {
      id: excel_upload_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  let file_upload_response = "";
  console.log("file_upload_response", file_upload_response);

  const action = file_upload_response?.data?.get_signed_url?.url;

  const handle_upload = ({ onSuccess, onError, file }) => {
    console.log("fileeessss", file);
    axios
      .put(file_list?.[0]?.url, file, {
        headers: { "Content-Type": file.type },
      })
      .then((response) => {
        file.url = file_list?.[0]?.url.split("?")[0];

        onSuccess(null, file);
      })
      .catch((error) => {});
  };

  const handle_before_upload = async (file) => {
    const { name, type } = file;
    const valid_extensions = [".xlsx"];
    const is_valid_extensions = valid_extensions.some((ext) =>
      name.endsWith(ext)
    );
    if (is_valid_extensions) {
      let file_upload = {
        type: "photo",
        file_name: `${getUUID()}.xlsx`,
      };

      file_upload_response = await QueryRequest(fileQuery, file_upload);
      console.log("file_upload_response", file_upload_response);
      set_error_message("");
    } else {
      set_error_message(
        t(
          "Invalid file format. You can only upload PDF, PNG, JPG, or JPEG files."
        )
      );
      return Upload.LIST_IGNORE;
    }
  };

  const handle_file_changed = ({ file }) => {
    console.log("filesssss", file);
    if (file?.status === "removed") {
      set_file_list([]);
    } else if (file?.status === "uploading") {
      file = {
        ...file,
        url: file_upload_response?.data?.get_signed_url?.url,
      };
      set_file_list([file]);
      return file;
    } else if (file?.status === "done") {
      set_file_list([...file_list]);
      set_url(file?.url.split("?")[0]);
      return file;
    }
  };
  const handle_download = async () => {
    const imageUrl =
      "https://v-ecs-migration.blr1.vultrobjects.com/c8968060-4da1-474b-bfaf-9c2aaff1d408.xlsx";

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Sample Format.xlsx"; // Set the file name
      document.body.appendChild(link); // Append to the DOM
      link.click();
      document.body.removeChild(link); // Remove after clicking
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Form>
        <Form.Item>
          <Upload
            name="file"
            action={action}
            customRequest={(e) => handle_upload(e)}
            beforeUpload={(args) => handle_before_upload(args)}
            onChange={(e) => handle_file_changed(e)}
          >
            <Button
              type="primary"
              style={{ color: "white", border: "none", borderRadius: "10px" }}
              icon={<UploadOutlined />}
            >
              {t("upload_excel")}
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      <Button
        style={{
          backgroundColor: "green",
          color: "white",
          borderRadius: "10px",
          border: "none",
          marginLeft: "10px",
        }}
        onClick={handle_download}
      >
        Sample Format
      </Button>
    </div>
  );
};

export default MandramRenewalFileUpload;
