import React from 'react'
import PrintHeader from '../../common/print_header';
import { Table } from 'antd';
import { useDynamicSelector } from '../../../../services/redux';
import _orderBy from 'lodash/orderBy'
import moment from 'moment';
import { IS_TABLE_SCROLLABLE } from '../../../../helpers/constants';

const CorpusSummaryTable = () => {
    const { items: report_items, loading: corpus_summary_items_loading } =
    useDynamicSelector("get_corpus_payment_mode_summary");

  const corpus_summary_items = _orderBy(
    report_items,
    [(item) => moment(item?.adjusted_date_order)],
    ["asc"]
  );

  const grand_total_cash = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_cash_amount || 0),
        0
      )
    : 0;

  const grand_total_dd_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_dd_amount || 0),
        0
      )
    : 0;

  const grand_total_card_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_card_amount || 0),
        0
      )
    : 0;

  const grand_total_upi_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_upi_amount || 0),
        0
      )
    : 0;

  const grand_total = report_items
    ? report_items.reduce((sum, record) => sum + (record.total_amount || 0), 0)
    : 0;

  const columns = [
    {
      title: "Date",
      width: "200px",
      align: "center",
      width: "200px",
      render: (item) => {
        const date = item?.adjusted_date_order;
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "CASH",
      width: "150px",
      align: "right",
      width: "200px",
      render: (item) => {
        return (item?.total_cash_amount).toLocaleString("en-IN");
      },
    },
    {
      title: "DD",
      width: "200px",
      align: "right",
      render: (item) => {
        return (item?.total_dd_amount).toLocaleString("en-IN");
      },
    },
    {
      title: "CARD",
      width: "200px",
      align: "right",
      width: "200px",
      render: (item) => {
        return (item?.total_card_amount).toLocaleString("en-IN");
      },
    },
    {
      title: "UPI",
      width: "200px",
      align: "right",
      width: "200px",
      render: (item) => {
        return (item?.total_upi_amount).toLocaleString("en-IN");
      },
    },
    {
      title: "TOTAL",
      width: "200px",
      align: "right",
      width: "200px",
      render: (item) => {
        return (item?.total_amount).toLocaleString("en-IN");
      },
    },
  ];

  return (
    <>
    <div className="print-header" style={{ padding: "15px" }}>
      <PrintHeader title={"CORPUS SUMMARY"} />
      <h4
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        CORPUS SUMMARY
      </h4>
      <h5
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {report_date?.date} */}
      </h5>
    </div>
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
    >
      <Table
        data-name-table="district-wise-report"
        className="counter-user-wise-report screen-table"
        // className="payment_report_details"
        columns={columns}
        dataSource={corpus_summary_items}
        loading={corpus_summary_items_loading}
        pagination={false}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                  TOTAL
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {corpus_summary_items
                    ? corpus_summary_items
                        .reduce(
                          (sum, record) =>
                            sum + (record.total_cash_amount || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {corpus_summary_items
                    ? corpus_summary_items
                        .reduce(
                          (sum, record) =>
                            sum + (record.total_dd_amount || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {corpus_summary_items
                    ? corpus_summary_items
                        .reduce(
                          (sum, record) =>
                            sum + (record.total_card_amount || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {corpus_summary_items
                    ? corpus_summary_items
                        .reduce(
                          (sum, record) =>
                            sum + (record.total_upi_amount || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {corpus_summary_items
                    ? corpus_summary_items
                        .reduce(
                          (sum, record) => sum + (record.total_amount || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      ></Table>

      <div style={{ padding: "10px" }}>
        <table
          className="table table-bordered print-table"
          style={{ padding: "10px", width: "100%", marginTop: "10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {report_items?.map((report, index) => (
              <tr key={index}>
                <td className="body-style text-left-align">
                  {moment(report?.adjusted_date_order).format("DD-MM-YYYY")}
                </td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  {`₹${(report?.total_cash_amount).toLocaleString("en-IN")}`}
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {`₹${(report?.total_dd_amount).toLocaleString("en-IN")}`}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {`₹${(report?.total_card_amount).toLocaleString("en-IN")}`}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {`₹${(report?.total_upi_amount).toLocaleString("en-IN")}`}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {`₹${(report?.total_amount).toLocaleString("en-IN")}`}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>Total</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{`₹${grand_total_cash.toLocaleString("en-IN")}`}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{`₹${grand_total_dd_amount.toLocaleString("en-IN")}`}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{`₹${grand_total_card_amount.toLocaleString("en-IN")}`}</b>
              </td>

              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{`₹${grand_total_upi_amount.toLocaleString("en-IN")}`}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{`₹${grand_total.toLocaleString("en-IN")}`}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>
        {`
  @media screen {
    .print-table {
      display: none;
    }
    .print-header {
      display: none;
    }
  }

  @media print {
    .screen-table {
      display: none !important;
    }
    .print-header {
      display: block !important;
    }

    @media print {
      @page {
        size: A4;
        margin: 1cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  }
`}
      </style>
    </div>
  </>
  )
}

export default CorpusSummaryTable