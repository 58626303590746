import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { Table } from "antd";
import { getAmountFormatWithSymbol } from "../../../../helpers/functions";
import PrintHeader from "../../common/print_header";
import { StyleSheet } from "@react-pdf/renderer";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";
import moment from "moment";

const GroupWiseEntryTable = (props) => {
  const { selected_from_date, selected_to_date } = props;
  const { items: branch_wise_items, loading: counter_group_items_loading } =
    useDynamicSelector("get_branch_wise_summary");

  // Calculate totals for each column
  const grand_total_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.total_enrolments || 0),
    0
  );

  const grand_total_devotees = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.total_devotees || 0),
    0
  );

  const grand_total_cash_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.cash_enrolments || 0),
    0
  );

  const grand_total_cash_amount = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.cash_amount || 0),
    0
  );

  const grand_total_card_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.card_enrolments || 0),
    0
  );

  const grand_total_card_amount = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.card_amount || 0),
    0
  );

  const grand_total_upi_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.upi_enrolments || 0),
    0
  );

  const grand_total_upi_amount = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.upi_amount || 0),
    0
  );

  const grand_total_dd_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.dd_enrolments || 0),
    0
  );

  const grand_total_dd_amount = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.dd_amount || 0),
    0
  );
  const grand_total_online_enrolments = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.online_enrolments || 0),
    0
  );

  const grand_total_online_amount = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.online_amount || 0),
    0
  );
  const grand_total = branch_wise_items?.reduce(
    (acc, item) => acc + (item?.sum || 0),
    0
  );

  const totals = {
    counter_group_name: <b>Total</b>,
    total_enrolments: <b>{`${grand_total_enrolments}`}</b>,
    total_devotees: <b>{`${grand_total_devotees}`}</b>,
    cash_enrolments: <b>{`${grand_total_cash_enrolments}`}</b>,
    cash_amount: <b>{`₹${grand_total_cash_amount}`}</b>,
    card_enrolments: <b>{`${grand_total_card_enrolments}`}</b>,
    card_amount: <b>{`₹${grand_total_card_amount}`}</b>,
    upi_enrolments: <b>{`${grand_total_upi_enrolments}`}</b>,
    upi_amount: <b>{`₹${grand_total_upi_amount}`}</b>,
    sum: <b>{`₹${grand_total}`}</b>,
    dd_amount: <b>{`₹${grand_total_dd_amount}`}</b>,
    dd_enrolments: <b>{`${grand_total_dd_enrolments}`}</b>,
    online_amount: <b>{`₹${grand_total_online_amount}`}</b>,
    online_enrolments: <b>{`${grand_total_online_enrolments}`}</b>,
  };

  const styles = StyleSheet.create({
    tableCell: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  });

  const columns = [
    {
      title: "Date",
      key: "date",
      fixed: "left",
      sorter: (a, b) => 
        moment(a?.date_slot?.date, "YYYY-MM-DD").unix() - 
        moment(b?.date_slot?.date, "YYYY-MM-DD").unix(),
      defaultSortOrder: "ascend",
      render: (data) => moment(data?.date_slot?.date).format("DD/MM/YYYY"),
    },
    {
      title: "Total Enrolments",
      align: "right",
      key: "total_enrolments",
      render: (data) => data?.total_enrolments,
    },
    {
      title: "Total Devotees",
      key: "devotees",
      align: "right",
      render: (data) => data?.total_devotees,
    },
    {
      title: "Cash Enrolments",
      key: "cash_enrolments",
      align: "right",
      render: (data) => data?.cash_enrolments,
    },
    {
      title: "Cash Amount",
      key: "cash_amount",
      align: "right",
      render: (data) => `₹${data?.cash_amount}`,
    },

    {
      title: "Card Amount",
      key: "card_amount",
      align: "right",
      render: (data) => `₹${data?.card_amount}`,
    },
    {
      title: "Card Enrolments",
      key: "card_enrolments",
      align: "right",
      render: (data) => data?.card_enrolments,
    },
    {
      title: "UPI Amount",
      key: "upi_amount",
      align: "right",
      render: (data) => `₹${data?.upi_amount}`,
    },
    {
      title: "UPI Enrolments",
      key: "upi_enrolments",
      align: "right",
      render: (data) => data?.upi_enrolments,
    },
    {
      title: "DD Enrolments",
      key: "dd_enrolments",
      align: "right",
      render: (data) => data?.dd_enrolments,
    },
    {
      title: "DD Amount",
      key: "dd_amount",
      align: "right",
      render: (data) => `₹${data?.dd_amount}`,
    },
    {
      title: "ONLINE Enrolments",
      key: "online_enrolments",
      align: "right",
      render: (data) => data?.online_enrolments,
    },
    {
      title: "ONLINE Amount",
      key: "online_amount",
      align: "right",
      render: (data) => `₹${data?.online_amount}`,
    },
    {
      title: "Total",
      key: "sum",
      align: "right",
      fixed: "right",
      render: (data) => `₹${data?.sum}`,
    },
  ];
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <Table
        style={{ width: "96%", margin: "auto" }}
        className="screen-table "
        data-name-table="district-wise-report"
        columns={columns}
        loading={counter_group_items_loading}
        dataSource={branch_wise_items ? [...branch_wise_items] : []}
        pagination={false}
        scroll={{
          x: 1920,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold" }}>TOTAL</text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1}>
                <text style={styles.tableCell}>
                  <b>{grand_total_enrolments}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <text style={styles.tableCell}>
                  <b>{grand_total_devotees}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={4}>
                <text style={styles.tableCell}>
                  <b> {grand_total_cash_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={6}>
                <text style={styles.tableCell}>
                  <b> {getAmountFormatWithSymbol(grand_total_card_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <text style={styles.tableCell}>
                  <b>{grand_total_card_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_upi_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_dd_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_dd_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{grand_total_online_enrolments}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <text style={styles.tableCell}>
                  <b>{getAmountFormatWithSymbol(grand_total)}</b>
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
      <div style={{ padding: "10px" }}>
        <div className="print-header" style={{ padding: "15px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            BRANCH WISE ENTRY REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {`${branch_wise_items?.[0]?.counter_group_name} Branch Wise Report (${selected_from_date} to ${selected_to_date})`}
          </h5>
        </div>

        <table
          className="table table-bordered print-table"
          style={{ width: "99%", marginTop: "10px", marginLeft: "-10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {branch_wise_items?.map((report, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {moment(report?.date_slot?.date).format("DD/MM/YYYY")}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.total_devotees}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.cash_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.cash_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.card_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.card_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.upi_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.upi_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.dd_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.dd_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.online_enrolments}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.online_amount)}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {getAmountFormatWithSymbol(report?.sum)}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>Total</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b> {grand_total_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_devotees}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_cash_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_cash_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_card_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_card_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_upi_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_upi_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_dd_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_dd_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{grand_total_online_enrolments}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total_online_amount)}</b>
              </td>
              <td className="body-style " style={{ textAlign: "right" }}>
                <b>{getAmountFormatWithSymbol(grand_total)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <style>
        {`
  @media screen {
    .print-table {
      display: none;
    }
    .print-header {
      display: none;
    }
  }

  @media print {
    .screen-table {
      display: none !important;
    }
    .print-header {
      display: block !important;
    }

    @page {
      size: A4 landscape;
      margin: 1cm;
    }

    body::before {
      content: counter(page);
      top: 0;
      right: 1cm;
    }
  }
`}
      </style>
    </div>
  );
};

export default GroupWiseEntryTable;
