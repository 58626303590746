import { Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import GroupWiseEntryForm from "../components/system_report/group_wise_entry_report/group_wise_entry_form";
import { dynamicClear, useDynamicSelector } from "../../services/redux";
import GroupWiseEntryTable from "../components/system_report/group_wise_entry_report/group_wise_entry_table";
import LoaderSpinner from "../components/common/custom_spinner";
import { useDispatch } from "react-redux";
import CustomButton from "../components/common/custom_button";
import { useReactToPrint } from "react-to-print";
import { export_to_xlsx } from "../../helpers/functions";
import moment from "moment";

const GroupWiseEntryReport = () => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const dispatch = useDispatch();

  const [selected_from_date, set_selected_from_date] = useState();
  const [selected_to_date, set_selected_to_date] = useState();
  console.log("selected_from_date", selected_from_date, selected_to_date);

  const { items: branch_wise_items, loading: branch_wise_items_loading } =
    useDynamicSelector("get_branch_wise_summary");

  useEffect(() => {
    dispatch(dynamicClear("get_branch_wise_summary"));
  }, []);

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let grand_total = 0;
    let total_enrolments = 0;
    let total_devotees = 0;
    let cash_enrolments = 0;
    let cash_amount = 0;
    let dd_amount = 0;
    let dd_enrolments = 0;
    let card_enrolments = 0;
    let card_amount = 0;
    let upi_enrolments = 0;
    let upi_amount = 0;
    let online_enrolments = 0;
    let online_amount = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Date"]:
          moment(data_items[i]?.date_slot?.date).format("DD/MM/YYYY") ?? "-",
        ["Total Enrolments"]: data_items[i]?.total_enrolments ?? "-",
        ["Total Devotees"]: data_items[i]?.total_devotees ?? "-",
        ["Cash Enrolments"]: data_items[i]?.cash_enrolments ?? "-",
        ["Cash Amount"]: data_items[i]?.cash_amount ?? "-",
        ["Card Amount"]: data_items[i]?.card_amount ?? "-",
        ["Card Enrolments"]: data_items[i]?.card_enrolments ?? "-",
        ["UPI Amount"]: data_items[i]?.upi_amount ?? "-",
        ["UPI Enrolments"]: data_items[i]?.upi_enrolments ?? "-",
        ["DD Amount"]: data_items[i]?.dd_amount ?? "-",
        ["DD Enrolments"]: data_items[i]?.dd_enrolments ?? "-",
        ["ONLINE Amount"]: data_items[i]?.online_amount ?? "-",
        ["ONLINE Enrolments"]: data_items[i]?.online_enrolments ?? "-",
        ["Total"]: data_items[i]?.sum ?? "-",
      };

      rearranged_data.push(row_data);

      total_enrolments += parseFloat(data_items[i]?.total_enrolments) || 0;
      total_devotees += parseFloat(data_items[i]?.total_devotees) || 0;
      cash_enrolments += parseFloat(data_items[i]?.cash_enrolments) || 0;
      cash_amount += parseFloat(data_items[i]?.cash_amount) || 0;
      dd_amount += parseFloat(data_items[i]?.dd_amount) || 0;
      dd_enrolments += parseFloat(data_items[i]?.dd_enrolments) || 0;
      card_enrolments += parseFloat(data_items[i]?.card_enrolments) || 0;
      card_amount += parseFloat(data_items[i]?.card_amount) || 0;
      upi_enrolments += parseFloat(data_items[i]?.upi_enrolments) || 0;
      upi_amount += parseFloat(data_items[i]?.upi_amount) || 0;
      online_enrolments += parseFloat(data_items[i]?.online_enrolments) || 0;
      online_amount += parseFloat(data_items[i]?.online_amount) || 0;
      grand_total += parseFloat(data_items[i]?.sum) || 0;
    }

    // Add Grand Total Row
    rearranged_data.push({
      ["S.No"]: "Grand Total",
      ["Total Enrolments"]: total_enrolments,
      ["Total Devotees"]: total_devotees,
      ["Cash Enrolments"]: cash_enrolments,
      ["Cash Amount"]: cash_amount,
      ["Card Amount"]: card_amount,
      ["Card Enrolments"]: card_enrolments,
      ["UPI Amount"]: upi_amount,
      ["UPI Enrolments"]: upi_enrolments,
      ["DD Enrolments"]: dd_enrolments,
      ["DD Amount"]: dd_amount,
      ["ONLINE Enrolments"]: online_enrolments,
      ["ONLINE Amount"]: online_amount,
      ["Sum"]: grand_total,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(branch_wise_items);

    if (arranged_data.length > 0) {
      export_to_xlsx(arranged_data, "branch_wise_entry_report");
    } else {
      console.error("No data available for Excel download.");
    }
  };

  const handle_print = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
          @page {
            size: landscape;
            margin: 20mm;
          }
        `,
  });

  const on_print = () => {
    handle_print();
  };
  return (
    <Col>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("group_wise_entry_report")} />
        </Row>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <GroupWiseEntryForm
          set_selected_from_date={set_selected_from_date}
          set_selected_to_date={set_selected_to_date}
        />
      </div>
      {branch_wise_items_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      {branch_wise_items?.length > 0 ? (
        <>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <CustomButton
              id="daily_transaction_print_button"
              disabled={branch_wise_items?.length > 0 ? false : true}
              button_name={"Print"}
              bg_color={"#1e2c55"}
              rounded={true}
              onclick_button={on_print}
            />
            <CustomButton
              disabled={branch_wise_items?.length > 0 ? false : true}
              button_name={"Download"}
              bg_color={"#185c37"}
              rounded={true}
              onclick_button={on_xlsx_download}
            />
          </Row>
          <div style={{ marginTop: "20px" }}>
            <h4
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {branch_wise_items?.[0]?.counter_group_name} Branch Wise Report
            </h4>
          </div>
          <div style={{ marginTop: "20px" }} ref={componentRef}>
            <GroupWiseEntryTable selected_from_date={selected_from_date} selected_to_date={selected_to_date} />
          </div>
        </>
      ) : null}
    </Col>
  );
};

export default GroupWiseEntryReport;
