import { Col, Empty, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import CorpusReportForm from "../components/reports_page/corpus_report/corpus_report_form";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  corpus_report_date_query,
  get_corpus_list_query,
} from "../../services/redux/slices/graphql/graphql_corpus_report";
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { secretary_name } from "../../helpers/constants";

const CorpusReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [from_date, set_from_date] = useState();
  const [to_date, set_to_date] = useState();
  const [selected_district, set_selected_district] = useState();
  const [selected_type, set_selected_type] = useState("");
  const [is_vip, set_is_vip] = useState(false);
  const [district_name, set_district_name] = useState();
  const [value_change, set_value_change] = useState();
  const [selected_from_date, set_selected_from_date] = useState();
  const [selected_to_date, set_selected_to_date] = useState();

  console.log(
    "selected_district",
    selected_district,
    selected_from_date,
    selected_to_date
  );

  const { items } = useDynamicSelector("get_corpus_list");
  console.log("dateeeeeee", items);

  useEffect(() => {
    if (items && items?.length) {
      if (selected_district === "all_district") {
        export_to_excel_for_all_districts();
      } else {
        exportToExcel();
      }
      dispatch(dynamicClear("get_corpus_list"));
    }
  }, [items, selected_district]);

  useEffect(() => {
    get_dates_for_om_sakthi_report();
  }, []);

  const get_dates_for_om_sakthi_report = () => {
    let key = [{ key: "corpus_report_date", loading: true }];
    let query = corpus_report_date_query;
    dispatch(dynamicRequest(key, query));
  };

  const get_corpus_report = () => {
    let key = [{ key: "get_corpus_list", loading: true }];
    let query = get_corpus_list_query;
    let variables = {
      filter: {
        from_date: from_date,
        to_date: to_date,
        district_id: selected_district,
        type: selected_type,
        is_vip: is_vip,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const secretaryLookup = secretary_name?.reduce((acc, item) => {
    acc[item?.District?.toUpperCase()] = item; // Use uppercase to ensure case-insensitive matching
    return acc;
  }, {});

  const map_payment_mode = (payment_mode) => {
    switch (payment_mode) {
      case "card":
        return "CARD";
      case "cash":
        return "CASH";
      case "upi":
        return "UPI";
      case "dd":
        return "DD";
      default:
        return payment_mode;
    }
  };

  const exportToExcel = async () => {
    if (!items || items.length === 0) {
      console.error("No data available to export");
      return;
    }

    const workbook = new ExcelJS.Workbook();

    // Group items by adjusted_date_order
    const groupedData = items.reduce((groups, item) => {
      const date = item.adjusted_date_order;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    let sheet_index = 1;

    // Iterate over each group to create a separate sheet
    Object.keys(groupedData).forEach((date) => {
      const worksheet = workbook.addWorksheet(`${date}`);
      const districtName = groupedData[date][0].district_name.toUpperCase(); // Ensure case-insensitive matching
      const secretaryDetails = secretaryLookup[districtName] || {
        name: " ",
        mobile: " ",
      };

      // Add headers for each sheet
      const headers = [
        ["Melmaruvathur Adiparasakthi Spiritual Movement"],
        [
          "(Adhiparasakthi Siddhar Peedam Melmaruvathur, Chengalpattu District, TamilNadu-603319)",
        ],
        [
          selected_type === "vc" && is_vip
            ? "Contributions towards VC of MASM (VIP Ticket)"
            : selected_type === "corpus" && is_vip
            ? "Contributions towards CORPUS of MASM (VIP Ticket)"
            : selected_type === "corpus" && !is_vip
            ? "Contributions towards CORPUS of MASM"
            : "Contributions towards VC of MASM",
        ],
        [],
        ["S.No:", sheet_index++, "", "", "", "", "Date", date,"",""],
        [
          "",
          "",
          "District:",
          groupedData[date][0].district_name,
          "President Name:",
          secretaryDetails?.name,
          "",
          "",
          "",
          ""
        ],
        [
          "",
          "",
          "President Ph:",
          secretaryDetails.mobile,
          "Month:",
          "December - January",
          "",
          "",
          "",
          ""
        ],
        ["", "", "Year:", "2024 - 2025", "", "", "", "", "",""],
        ["", "", " ", " ", "", "", "", "", "",""],
        [],
        [],
        [
          "We the following individual donors declare that the amounts mentioned against our respective names are our donations towards the corpus of the Trust.",
        ],
        [],
        [
          "Receipt No",
          "Date",
          "VSG",
          "Leader Name",
          "Address",
          "Ph No",
          "ID Details",
          "Payment mode",
          "Amount (INR)",
          "Total Devotees",
        ],
      ];

      headers.forEach((row) => worksheet.addRow(row));

      // Apply bold font to the header row
      const headerRow = worksheet.getRow(14); // Assuming the header row is the 14th row
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 12 };
      });

      // Adjust column widths
      worksheet.columns = [
        { key: "col1", width: 15 }, // Receipt No
        { key: "col2", width: 15 }, // Date
        { key: "col3", width: 25 }, // Mandram
        { key: "col4", width: 30 }, // Name
        { key: "col5", width: 40 }, // Address
        { key: "col6", width: 15 }, // Ph No
        { key: "col7", width: 25 }, // ID Details
        { key: "col8", width: 25 }, // Payment mode
        { key: "col9", width: 20 }, // Amount
        { key: "col10", width: 15 }, // Total Devotees
      ];

      // Center-align and merge first row without bottom border
      worksheet.mergeCells("A1:J1");
      const titleRow = worksheet.getRow(1);
      titleRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      titleRow.getCell(1).font = { bold: true, size: 14 };
      titleRow.getCell(1).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Center-align and merge second row without bottom border
      worksheet.mergeCells("A2:J2");
      const subtitleRow = worksheet.getRow(2);
      subtitleRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      subtitleRow.getCell(1).font = { bold: true, size: 12 };
      subtitleRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Center-align and merge third row without bottom border
      worksheet.mergeCells("A3:J3");
      const contributionsRow = worksheet.getRow(3);
      contributionsRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      contributionsRow.getCell(1).font = { bold: true, size: 12 };
      contributionsRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Center-align and merge the declaration row
      worksheet.mergeCells("A12:J12");
      const declarationRow = worksheet.getRow(12);
      declarationRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      declarationRow.getCell(1).font = { bold: true, size: 12 };
      declarationRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Populate rows with data for the specific date
      groupedData[date].forEach((item, index) => {
        const calculated_vip_amount = (item.devotte_count || 0) * 1000;
        const calculated_amount = (item.devotte_count || 0) * 350; // Calculate amount by multiplying devotte_count with 350
        const full_mandram_name = `${item.mandram_name} ${item.mandram_code}`; // Concatenate full mandram name and code

        worksheet.addRow([
          item.receipt_no,
          item.adjusted_date_order,
          full_mandram_name,
          item.leader_name,
          item.address,
          item.mobile,
          `${item.id_type} - ${item.aadhar_no}`,
          map_payment_mode(item?.payment_mode),
          is_vip ? calculated_vip_amount : calculated_amount,
          item.devotte_count,
        ]);
      });

      // Add footer rows
      worksheet.addRow([]);
      const totalDevotees = groupedData[date].reduce(
        (sum, item) => sum + (item.devotte_count || 0),
        0
      );

      const vip_total_amount = totalDevotees * 1000;
      const totalAmount = totalDevotees * 350;
      const grandTotalRow = worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "Grand Total",
        "",
        is_vip ? vip_total_amount : totalAmount, // Total Amount
        totalDevotees, // Total Devotees
      ]);
      grandTotalRow.getCell(6).font = { bold: true, size: 12 };
      grandTotalRow.getCell(6).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.addRow([]);
      worksheet.addRow([
        "President's / Authorised signatory:",
        `\n\n\n${secretaryDetails.name}`,
        "",
        "",
        "",
        "",
        "",
        "Place:",
        groupedData[date][0].district_name,
        ""
      ]);
      worksheet.addRow([]);
      worksheet.addRow([
        "Declaration: I hereby declare that I have collected the above amounts from the donors and that the said corpus donations declarations have been made by the individual donors.",
      ]);

      // Center-align and merge the final declaration row
      worksheet.mergeCells(
        `A${worksheet.lastRow.number}:J${worksheet.lastRow.number}`
      );
      const finalDeclarationRow = worksheet.getRow(worksheet.lastRow.number);
      finalDeclarationRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      finalDeclarationRow.getCell(1).font = { bold: true, size: 12 };
      finalDeclarationRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Apply borders and alignment to all rows
      worksheet.eachRow((row, rowIndex) => {
        row.eachCell((cell) => {
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true, // Enable text wrapping
          };
          cell.border = {
            top: { style: "thin" },
            bottom: { style: rowIndex > 2 ? "thin" : undefined },
            left: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
    });

    const summarySheet = workbook.addWorksheet("Summary");

    // Create an object to accumulate the total amounts per Mandram
    const mandramTotals = items.reduce((acc, item) => {
      const mandramKey = `${item.mandram_name} (${item.mandram_code})`;
      const amount = (item.devotte_count || 0) * 350;

      if (!acc[mandramKey]) {
        acc[mandramKey] = 0;
      }
      acc[mandramKey] += amount;
      return acc;
    }, {});

    // Add header row for the summary sheet
    const headerRow = summarySheet.addRow([
      "Mandram Name",
      "Mandram Code",
      "Total Amount",
    ]);

    // Apply bold font and thick borders to header row
    headerRow.eachCell((cell) => {
      cell.font = {
        bold: true,
        size: 12, // Optional: Set the font size if needed
      };
      cell.border = {
        top: { style: "thick" },
        bottom: { style: "thick" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let grandTotalAmount = 0;

    Object.keys(mandramTotals).forEach((mandramKey) => {
      const mandramCodeMatch = mandramKey.match(/\(([^)]+)\)$/); // Extract Mandram Code
      const mandramCode = mandramCodeMatch ? mandramCodeMatch[1] : ""; // Extract Mandram Code
      const mandramName = mandramCode
        ? mandramKey.replace(`(${mandramCode})`, "").trim()
        : mandramKey;

      const totalAmount = mandramTotals[mandramKey];
      grandTotalAmount += totalAmount;

      summarySheet.addRow([
        mandramName, // Mandram Name
        mandramCode, // Mandram Code
        totalAmount, // Total Amount
      ]);
    });

    // Add a row for the total sum
    const totalRow = summarySheet.addRow([
      "Total", // Label
      "", // Empty cell for Mandram Code
      grandTotalAmount, // Sum of all Total Amounts
    ]);

    // Apply bold and centered formatting to "Total" row
    totalRow.getCell(1).font = { bold: true }; // "Total" label bold
    totalRow.getCell(3).font = { bold: true }; // Total amount bold
    totalRow.getCell(1).alignment = {
      horizontal: "center", // Center the "Total" label
      vertical: "middle",
    };
    totalRow.getCell(3).alignment = {
      horizontal: "center", // Center the total amount
      vertical: "middle",
    };

    // Set summary sheet column widths
    summarySheet.columns = [
      { key: "col1", width: 50 }, // Mandram Name
      { key: "col2", width: 20 }, // Mandram Code
      { key: "col3", width: 20 }, // Total Amount
    ];

    // Optional: Apply additional formatting to the summary sheet (alignment, etc.)
    summarySheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        cell.alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true, // Enable text wrapping
        };
        cell.border = {
          top: { style: "thin" },
          bottom: { style: rowIndex > 2 ? "thin" : undefined },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Contribution_MASM-${items?.[0]?.district_name}_${selected_from_date}_to_${selected_to_date}.xlsx`;
    link.click();
  };
  const export_to_excel_for_all_districts = async () => {
    if (!items || items.length === 0) {
      console.error("No data available to export");
      return;
    }

    const workbook = new ExcelJS.Workbook();

    // Group items by adjusted_date_order
    const groupedData = items.reduce((groups, item) => {
      const date = item.adjusted_date_order;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});

    let sheet_index = 1;

    // Iterate over each group to create a separate sheet
    Object.keys(groupedData).forEach((date) => {
      const worksheet = workbook.addWorksheet(`${date}`);
      const districtName = groupedData[date][0].district_name.toUpperCase(); // Ensure case-insensitive matching
      const secretaryDetails = secretaryLookup[districtName] || {
        name: "Not Found",
        mobile: "N/A",
      };

      // Add headers for each sheet
      const headers = [
        ["Melmaruvathur Adiparasakthi Spiritual Movement"],
        [
          "(Adhiparasakthi Siddhar Peedam Melmaruvathur, Chengalpattu District, TamilNadu-603319)",
        ],
        [
          selected_type === "vc"
            ? "Contributions towards VC of MASM"
            : selected_type === "corpus"
            ? "Contributions towards CORPUS of MASM"
            : "Contributions towards VIP of MASM",
        ],
        [],
        [
          selected_type === "vc" && is_vip
            ? "ALL DISTRICTS VC REPORT (VIP Ticket)"
            : selected_type === "corpus" && is_vip
            ? "ALL DISTRICTS CORPUS REPORT (VIP Ticket)"
            : selected_type === "corpus" && !is_vip
            ? "ALL DISTRICT CORPUS REPORT"
            : "ALL DISTRICTS VC REPORT",
        ],
        [],
        [
          "Receipt No",
          "Date",
          "District",
          "VSG",
          "Leader Name",
          "Address",
          "Ph No",
          "ID Details",
          "Payment mode",
          "Amount (INR)",
          "Total Devotees",
        ],
      ];
      headers.forEach((row) => worksheet.addRow(row));

      // Apply bold font to the header row
      const headerRow = worksheet.getRow(5); // Assuming the header row is the 14th row
      headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 12 };
      });

      // Adjust column widths
      worksheet.columns = [
        { key: "col1", width: 15 }, // Receipt No
        { key: "col2", width: 15 }, // Date
        { key: "col3", width: 15 }, // Date
        { key: "col4", width: 25 }, // Mandram
        { key: "col5", width: 30 }, // Name
        { key: "col6", width: 40 }, // Address
        { key: "col7", width: 15 }, // Ph No
        { key: "col8", width: 25 }, // ID Details
        { key: "col9", width: 25 }, // Payment mode
        { key: "col10", width: 20 }, // Amount
        { key: "col11", width: 15 }, // Total Devotees
      ];

      // Center-align and merge first row without bottom border
      worksheet.mergeCells("A1:K1");
      const titleRow = worksheet.getRow(1);
      titleRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      titleRow.getCell(1).font = { bold: true, size: 14 };
      titleRow.getCell(1).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Center-align and merge second row without bottom border
      worksheet.mergeCells("A2:K2");
      const subtitleRow = worksheet.getRow(2);
      subtitleRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      subtitleRow.getCell(1).font = { bold: true, size: 12 };
      subtitleRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Center-align and merge third row without bottom border
      worksheet.mergeCells("A3:K3");
      const contributionsRow = worksheet.getRow(3);
      contributionsRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      contributionsRow.getCell(1).font = { bold: true, size: 12 };
      contributionsRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.mergeCells("A5:K5");
      const titlesRow = worksheet.getRow(3);
      titlesRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      titlesRow.getCell(1).font = { bold: true, size: 12 };
      titlesRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Populate rows with data for the specific date
      groupedData[date].forEach((item, index) => {
        const calculated_vip_amount = (item.devotte_count || 0) * 1000;
        const calculated_amount = (item.devotte_count || 0) * 350; // Calculate amount by multiplying devotte_count with 350
        const full_mandram_name = `${item.mandram_name} ${item.mandram_code}`; // Concatenate full mandram name and code

        worksheet.addRow([
          item.receipt_no,
          item.adjusted_date_order,
          item.district_name,
          full_mandram_name,
          item.leader_name,
          item.address,
          item.mobile,
          `${item.id_type} - ${item.aadhar_no}`,
          map_payment_mode(item?.payment_mode)||"-",
          is_vip ? calculated_vip_amount : calculated_amount,
          item.devotte_count,
        ]);
      });

      // Add footer rows
      worksheet.addRow([]);
      const totalDevotees = groupedData[date].reduce(
        (sum, item) => sum + (item.devotte_count || 0),
        0
      );

      const vip_total_amount = totalDevotees * 1000;
      const totalAmount = totalDevotees * 350;
      const grandTotalRow = worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Grand Total",
        "",
        is_vip ? vip_total_amount : totalAmount, // Total Amount
        totalDevotees, // Total Devotees
      ]);
      grandTotalRow.getCell(6).font = { bold: true, size: 12 };
      grandTotalRow.getCell(6).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.addRow([]);
      worksheet.addRow([
        "Authorised signatory:",
        `\n\n\n`,
        "",
        "",
        "",
        "",
        "",
        "Place:",
        "",
        "",
        ""
      ]);
      worksheet.addRow([]);
      worksheet.addRow([
        "Declaration: I hereby declare that I have collected the above amounts from the donors and that the said corpus donations declarations have been made by the individual donors.",
      ]);

      // Center-align and merge the final declaration row
      worksheet.mergeCells(
        `A${worksheet.lastRow.number}:K${worksheet.lastRow.number}`
      );
      const finalDeclarationRow = worksheet.getRow(worksheet.lastRow.number);
      finalDeclarationRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      finalDeclarationRow.getCell(1).font = { bold: true, size: 12 };
      finalDeclarationRow.getCell(1).border = {
        left: { style: "thin" },
        right: { style: "thin" },
      };

      // Apply borders and alignment to all rows
      worksheet.eachRow((row, rowIndex) => {
        row.eachCell((cell) => {
          cell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true, // Enable text wrapping
          };
          cell.border = {
            top: { style: "thin" },
            bottom: { style: rowIndex > 2 ? "thin" : undefined },
            left: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });
    });

    const summarySheet = workbook.addWorksheet("Summary");

    // Create an object to accumulate the total amounts per Mandram
    const mandramTotals = items.reduce((acc, item) => {
      const mandramKey = `${item.mandram_name} (${item.mandram_code})`;
      const amount = (item.devotte_count || 0) * 350;

      if (!acc[mandramKey]) {
        acc[mandramKey] = 0;
      }
      acc[mandramKey] += amount;
      return acc;
    }, {});

    // Add header row for the summary sheet
    const headerRow = summarySheet.addRow([
      "Mandram Name",
      "Mandram Code",
      "Total Amount",
    ]);

    // Apply bold font and thick borders to header row
    headerRow.eachCell((cell) => {
      cell.font = {
        bold: true,
        size: 12, // Optional: Set the font size if needed
      };
      cell.border = {
        top: { style: "thick" },
        bottom: { style: "thick" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
    });

    let grandTotalAmount = 0;

    Object.keys(mandramTotals).forEach((mandramKey) => {
      const mandramCodeMatch = mandramKey.match(/\(([^)]+)\)$/); // Extract Mandram Code
      const mandramCode = mandramCodeMatch ? mandramCodeMatch[1] : ""; // Extract Mandram Code
      const mandramName = mandramCode
        ? mandramKey.replace(`(${mandramCode})`, "").trim()
        : mandramKey;

      const totalAmount = mandramTotals[mandramKey];
      grandTotalAmount += totalAmount;

      summarySheet.addRow([
        mandramName, // Mandram Name
        mandramCode, // Mandram Code
        totalAmount, // Total Amount
      ]);
    });

    // Add a row for the total sum
    const totalRow = summarySheet.addRow([
      "Total", // Label
      "", // Empty cell for Mandram Code
      grandTotalAmount, // Sum of all Total Amounts
    ]);

    // Set summary sheet column widths
    summarySheet.columns = [
      { key: "col1", width: 50 }, // Mandram Name
      { key: "col2", width: 20 }, // Mandram Code
      { key: "col3", width: 20 }, // Total Amount
    ];

    // Optional: Apply additional formatting to the summary sheet (alignment, etc.)
    summarySheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        cell.alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true, // Enable text wrapping
        };
        cell.border = {
          top: { style: "thin" },
          bottom: { style: rowIndex > 2 ? "thin" : undefined },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    totalRow.getCell(1).font = { bold: true }; // "Total" label
    totalRow.getCell(3).font = { bold: true }; // Total amount

    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Contribution_MASM_ALL_DISTRICTS_${selected_from_date}_to_${selected_to_date}.xlsx`;
    link.click();
  };

  return (
    <Col>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("corpus_report")} />
        </Row>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <CorpusReportForm
          set_from_date={set_from_date}
          set_to_date={set_to_date}
          set_selected_district={set_selected_district}
          set_district_name={set_district_name}
          set_value_change={set_value_change}
          on_finish={get_corpus_report}
          set_selected_from_date={set_selected_from_date}
          set_selected_to_date={set_selected_to_date}
          set_selected_type={set_selected_type}
          set_is_vip={set_is_vip}
        />
      </div>
      {items?.length <= 0 && (
        <div style={{ marginTop: "30px" }}>
          <Empty description="No Data" />
        </div>
      )}
    </Col>
  );
};

export default CorpusReport;
