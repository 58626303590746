import React, { useEffect, useRef } from "react";
import CorpusSummaryTable from "../components/system_report/corpus_report_summary/corpus_summary_table";
import { Card, Col, Row } from "antd";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/common/custom_button";
import { useReactToPrint } from "react-to-print";
import { dynamicRequest, useDynamicSelector } from "../../services/redux";
import { get_corpus_summary_query } from "../../services/redux/slices/graphql/graphql_corpus_report";
import { useDispatch } from "react-redux";
import moment from "moment";
import { export_to_xlsx } from "../../helpers/functions";

const CorpusReportSummaryList = () => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const dispatch=useDispatch()

  const { items: report_items, loading: report_items_loading } =
    useDynamicSelector("get_corpus_payment_mode_summary");

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const on_print = () => {
    handlePrint();
  };

  const grand_total_cash = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_cash_amount || 0),
        0
      )
    : 0;

  const grand_total_dd_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_dd_amount || 0),
        0
      )
    : 0;

  const grand_total_card_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_card_amount || 0),
        0
      )
    : 0;

  const grand_total_upi_amount = report_items
    ? report_items.reduce(
        (sum, record) => sum + (record.total_upi_amount || 0),
        0
      )
    : 0;

  const grand_total = report_items
    ? report_items.reduce((sum, record) => sum + (record.total_amount || 0), 0)
    : 0;

  const arrange_xlsx_corpus_summary_data = (data_items) => {
    const rearranged_data = [];
    let devotees_total = 0;

    if (data_items && data_items.length) {
      for (let i = 0; i < data_items.length; i++) {
        let row_data = {
          ["S No"]: i + 1,
          ["Date"]:
            moment(data_items[i]?.adjusted_date_order).format("DD-MM-YYY") || "-",
          ["CASH"]: data_items[i]?.total_cash_amount || "0",
          ["DD"]: data_items[i]?.total_dd_amount || "0",
          ["CARD"]: data_items[i]?.total_card_amount || "0",
          ["UPI"]: data_items[i]?.total_upi_amount || "0",
          ["TOTAL"]: data_items[i]?.total_amount || "0",
        };
        rearranged_data.push(row_data);
        // devotees_total += parseFloat(data_items[i]?.devotee_count) || 0;
      }
    }

    rearranged_data.push({
      ["S No"]: "Total",
      ["CASH"]: grand_total_cash,
      ["DD"]: grand_total_dd_amount,
      ["CARD"]: grand_total_card_amount,
      ["UPI"]: grand_total_upi_amount,
      ["TOTAL"]: grand_total,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_corpus_summary_data(report_items);
    export_to_xlsx(arranged_data, "corpus_summary_details");
  };

  const get_corpus_summary = () => {
    let key = [{ key: "get_corpus_payment_mode_summary", loading: true }];
    let query = get_corpus_summary_query;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    get_corpus_summary();
  }, []);
  return (
    <Col>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("corpus_summary_list")} />
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ marginTop: "20px" }}>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <CustomButton
              button_name={"Print"}
              bg_color={"#1e2c55"}
              rounded={true}
              id="payment_report_print"
              onclick_button={on_print}
            />
            <CustomButton
              button_name={t("download")}
              bg_color={"#185c37"}
              rounded={true}
              // icon={<FileExcelOutlined />}
              onclick_button={on_xlsx_download}
              // disabled={sakthi_peedam_reports?.length > 0 ? false : true}
            />
          </Row>
        </div>
        <div ref={componentRef}>
          <CorpusSummaryTable />
        </div>
      </Card>
    </Col>
  );
};

export default CorpusReportSummaryList;
