import { Card, Col, Form, Row, Switch } from "antd";
import React from "react";
import CustomButton from "../../common/custom_button";
import { useTranslation } from "react-i18next";
import CorpusReportFromDate from "./corpus_report_from_date";
import CorpusReportToDate from "./corpus_report_to_date";
import CorpusReportDistrict from "./corpus_report_district";
import { useDynamicSelector } from "../../../../services/redux";
import CorpusReportType from "./corpus_report_type";
import CorpusReportVipInput from "./corpus_report_vip_input";

const CorpusReportForm = (props) => {
  const {
    set_from_date,
    set_to_date,
    set_selected_district,
    set_district_name,
    set_value_change,
    on_finish,
    set_selected_from_date,
    set_selected_to_date,
    set_selected_type,
    set_is_vip
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { loading: corpus_list_loading } =
    useDynamicSelector("get_corpus_list");

  const handle_finish = () => {
    on_finish();
  };

  return (
    <Form
      id="search"
      onFinish={handle_finish}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 15,
      }}
      form={form}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <CorpusReportFromDate
              set_from_date={set_from_date}
              set_selected_from_date={set_selected_from_date}
            />
          </Col>
          <Col span={10}>
            <CorpusReportToDate
              set_to_date={set_to_date}
              set_selected_to_date={set_selected_to_date}
            />
          </Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                button_name={t("get")}
                bg_color={"#014477"}
                rounded={true}
                is_submit={true}
                loading={corpus_list_loading}
              />
            </div>
          </Col>
        </Row>

        <Row gutter={24} style={{ height: "30px", marginTop: "20px" }}>
          <Col span={11}>
            <CorpusReportDistrict
              set_selected_district={set_selected_district}
              set_district_name={set_district_name}
              set_value_change={set_value_change}
            />
          </Col>
          <Col span={10}>
            <CorpusReportType set_selected_type={set_selected_type} />
          </Col>
         
        </Row>
        <Row gutter={24} style={{ height: "30px", marginTop: "20px" }}>
        <Col span={11}>
           <CorpusReportVipInput set_is_vip={set_is_vip}/>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default CorpusReportForm;
