import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { Form, Select } from "antd";
import { sort_date_slots } from "../../../../helpers/functions";

const GroupWiseEntryFromDate = ({ set_selected_from_date }) => {
  const dispatch = useDispatch();
  const [sorted_items, set_sorted_items] = useState([]);
  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  useEffect(() => {
    const sorted = sort_date_slots(date_slot_items);
    set_sorted_items(sorted);
  }, [date_slot_items]);

  const date_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variable = {
      filter: {},
    };
    dispatch(dynamicRequest(keys, query, variable));
  };
  const handle_change = (value, option) => {
    set_selected_from_date(option?.children);
  };


  useEffect(() => {
    date_list();
  }, []);
  return (
    <Form.Item
      name="from_date_slot_id"
      label="From Date"
      rules={[{ required: true, message: "Please select From Date" }]}
      allowClear={true}
    >
      <Select placeholder="Select From Date" allowClear={true}
          onChange={handle_change}
          >
        {sorted_items?.map((date, index) => (
          <Select.Option key={index} value={date?.id} label={date?.id}>
            {date.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default GroupWiseEntryFromDate;
