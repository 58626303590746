import { Form, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CorpusReportVipInput = ({set_is_vip}) => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="is_vip"
      label={t("VIP")}
    >
      <Switch onChange={(checked) => set_is_vip(checked)}/>
    </Form.Item>
  );
};

export default CorpusReportVipInput;
