import { gql } from "@apollo/client";

export const query_get_counter_group_list = gql`
  query get_counter_group_list(
    $pagination: pagination_input
    $sort: sort_input
    $filter: get_counter_group_list_filter_input
  ) {
    get_counter_group_list(
      pagination: $pagination
      sort: $sort
      filter: $filter
    ) {
      items {
        id
        name
      }
    }
  }
`;

export const query_get_group_wise_summary = gql`
  query get_counter_group_wise_summary(
    $filter: counter_group_wise_summary_input
  ) {
    get_counter_group_wise_summary(filter: $filter) {
      items {
        upi_enrolments
        upi_amount
        card_amount
        cash_enrolments
        cash_amount
        dd_enrolments
        dd_amount
        card_enrolments
        online_amount
      online_enrolments
        counter_name
        counter_group_name
        sum
        total_enrolments
        total_devotees
      }
    }
  }
`;

export const query_get_branch_wise_summary=gql`
query get_counter_group_wise_summary($filter: counter_group_wise_summary_input) {
  get_branch_wise_summary:get_counter_group_wise_summary(filter: $filter) {
    items {
      upi_enrolments
      upi_amount
      card_amount
      cash_enrolments
      cash_amount
      dd_enrolments
      dd_amount
      card_enrolments
      online_amount
      online_enrolments
      counter_name
      counter_group_name
      sum
      total_enrolments
      total_devotees
      date_slot{
        date
      }
    }
  }
}
`
