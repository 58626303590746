import { Form, Select } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CorpusReportType = ({set_selected_type}) => {
    const{t}=useTranslation()
    const custom_filter_option = (input, option) => {
        const inputValue = input.toLowerCase();
        const optionLabel = option.props.children.toLowerCase();
        return optionLabel.indexOf(inputValue) === 0;
      };
      const types=[{id:"corpus",name:"Corpus"},{id:"vc",name:"VC"}]
      const handle_change=(value)=>{
        set_selected_type(value)
      }
  return (
    <Form.Item
      name="type"
      label={t("type")}
      rules={[{ required: true, message: "Please Select Type" }]}
    >
      <Select
        showSearch
        allowClear
        filter
        filterOption={custom_filter_option}
        placeholder="Select Type"
        onChange={handle_change}
      >
        {types.map((option, index) => (
          <Select.Option key={index} value={option.id} label={option.name}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default CorpusReportType